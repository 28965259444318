document.getElementById("contactForm").addEventListener("submit", (e) => {
  e.preventDefault();
  let myForm = document.getElementById("contactForm");
  let formData = new FormData(myForm);
  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams(formData).toString(),
  })
    .then(() => {
      notyf.success("Die Nachricht wurde versendet!");
      myForm.reset();
    })
    .catch((error) =>
      notyf.error(
        "Es ist etwas schief gelaufen. Bitte versuche es später erneut."
      )
    );
});

const notyf = new Notyf({
  duration: 5000,
  dismissible: true,
});
